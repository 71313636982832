import styled from 'styled-components'

import * as colors from '../../constants/colors'
import * as effects from '../../constants/neue/effects'
import * as glyphs from '../../icons/glyphs'
import * as copy from '../copy'
import * as link from '../link'

import * as reward from './reward'

export const MembershipStatus = styled.div`
  box-sizing: border-box;
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 4px;
  box-shadow: ${effects.SHADOW_5};
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  span {
    align-self: flex-start;
    display: inline-flex;
    gap: 8px;

    ${glyphs.Icon} {
      color: ${({ theme }) => `${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
    }
  }
`

export const MembershipStatusEditCTA = styled(copy.S)`
  ${link.Base} {
    display: flex;
    align-items: center;
    font-weight: 600; // HACK: Irregular font manipulation.
    gap: 8px;
  }
`

export const MembershipCardSubTitle = styled(copy.S)`
  font-weight: 700; // HACK: Irregular font manipulation.
`

export const MembershipCardRole = styled(copy.S)`
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 8px;

  i,
  em {
    font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation.
  }

  p {
    margin: 0;
  }

  ${glyphs.Icon} {
    color: ${colors.PRIMARY_BLUE_500};
  }
`

export const CardImage = styled(reward.CardImage)`
  height: 136px;
`
