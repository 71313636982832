import * as entity from '@boutique/entities'
import * as cold from '@owl-nest/cold-data/client.ts'

type Crumb = { label: string; path: string }
export type Entry = { label: string; path: string; crumbs: Crumb[] }

function normalize(path: string) {
  if (process.env.NEXT_PUBLIC_BOUTIQUE_ROOT_URL) {
    return `${process.env.NEXT_PUBLIC_BOUTIQUE_ROOT_URL}${path}`
  }
  return path
}

export function homepage(): Entry {
  const crumb = {
    label: 'Accueil',
    path: normalize('/'),
  }
  return { ...crumb, crumbs: [crumb] }
}

export function aboutPage({ slug, title }: { slug: string; title: string }): Entry {
  const crumb = {
    label: title,
    path: normalize(`/${slug}`),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function cart(): Entry {
  const crumb = {
    label: 'Panier',
    path: normalize('/panier'),
  }
  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function giftIdeas(): Entry {
  const path = `/idees-cadeaux`

  const crumb = {
    label: 'Idées cadeaux',
    path: normalize(path),
  }

  return {
    ...crumb,
    crumbs: [...homepage().crumbs, crumb],
  }
}

export function products({
  query,
  title,
  currentTree,
}: {
  currentTree?: cold.SlimCold['hierarchy']['tree']
  query?: entity.search.product.Query
  title?: string
}): Entry {
  let path = '/produits'

  if (query) {
    path += `?${entity.search.product.toUrlSearchParams(query).toString()}`
  }

  const crumb = {
    label: title ?? 'Nos produits',
    path: normalize(path),
  }

  const crumbs = [...homepage().crumbs]

  if (currentTree === undefined || currentTree.type === '$$root$$') {
    crumbs.push(crumb)
  } else {
    const hierarchyCrumbs = currentTree.parents.map((parent) => {
      return {
        label: parent.name,
        path: normalize(
          `${path}${query ? '&' : '?'}${new URLSearchParams({
            hierarchy: parent.id,
          }).toString()}`,
        ),
      }
    })
    crumbs.push(...hierarchyCrumbs)
    crumbs.push({
      label: currentTree.name,
      path: normalize(
        `${path}${query ? '&' : '?'}${new URLSearchParams({
          hierarchy: currentTree.id,
        }).toString()}`,
      ),
    })
  }

  return {
    ...crumb,
    crumbs,
  }
}

export function product({
  handle,
  id,
  title,
  category,
  currentTree,
}: {
  currentTree?: cold.SlimCold['hierarchy']['tree']
  handle: string
  id: number
  title: string
  category?: { slug: string; name: string }
}): Entry {
  const crumb = {
    label: title,
    path: normalize(`/produit/${handle}-${id}`),
  }
  const params: { [name: string]: string } = {}
  if (category) {
    params.category = category.slug
  }

  return {
    ...crumb,
    crumbs: [
      ...products({
        query: { facetFilters: category ? { category: category.slug } : undefined },
        currentTree,
        title: category?.name,
      }).crumbs,
      crumb,
    ],
  }
}
