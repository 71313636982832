import styled, { css } from 'styled-components'

import { UseTransition } from '../behaviours/transition'
import * as colors from '../constants/colors'
import { hexToRgb } from '../utils/colors'
import * as glyphs from '../icons/glyphs'

export const NOTIFICATION_COLORS = {
  alert: colors.SECONDARY_YELLOW,
  error: colors.PRIMARY_RED_500,
  info: colors.PRIMARY_BLUE_500,
  success: colors.SECONDARY_GREEN_500,
}

export type NotificationType = keyof typeof NOTIFICATION_COLORS

export const BaseNotification = styled.div<{
  type: NotificationType
  step?: UseTransition['step']
}>`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  color: ${colors.PRIMARY_GREY_900};
  display: flex;
  gap: 16px;
  min-height: 50px;
  padding: 8px 16px;

  > ${glyphs.Icon} {
    flex: none;
    align-self: flex-start;
    position: relative;
    top: 6px;
  }

  /* transition properties */
  bottom: 0;
  position: relative;
  transition-property: bottom, opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);

  ${({ step }) => {
    switch (step) {
      case 'before':
      case 'after':
        return css`
          bottom: 50px;
          opacity: 0;
        `
    }
  }}

  ${({ type, theme }) => {
    if (theme?.colors?.primary) {
      return css`
        background: linear-gradient(
            ${hexToRgb(theme.colors.primary, type == 'info' ? 0.1 : 0.15)},
            ${hexToRgb(theme.colors.primary, type == 'info' ? 0.1 : 0.15)}
          ),
          ${colors.PRIMARY_GREY_000};
        border-bottom: 3px solid ${theme.colors.primary};

        ${glyphs.Icon} {
          color: ${theme.colors.primary};
        }
      `
    } else {
      return css`
        background: linear-gradient(
            ${hexToRgb(NOTIFICATION_COLORS[type], type == 'info' ? 0.1 : 0.15)},
            ${hexToRgb(NOTIFICATION_COLORS[type], type == 'info' ? 0.1 : 0.15)}
          ),
          ${colors.PRIMARY_GREY_000};
        border-bottom: 3px solid ${NOTIFICATION_COLORS[type]};

        ${glyphs.Icon} {
          color: ${NOTIFICATION_COLORS[type]};
        }
      `
    }
  }}
`

// CALLOUT
export const CalloutContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

// TOAST
export const ToastContentWrapper = styled.div`
  flex-grow: 1;
`

export const ToastContainer = styled.div<{
  type: NotificationType
}>`
  align-items: flex-end;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  justify-content: space-between;
`

// BANNER
export const BannerCloseButton = styled.button<{ visible: boolean }>`
  align-self: flex-start;
  background: none;
  border: none;
  cursor: pointer;
  flex: none;
  height: 30px;
  padding: 0;
  position: relative;
  right: -3px;
  width: 30px;

  ${({ visible }) => {
    return (
      !visible &&
      css`
        visibility: hidden;
        pointer-events: none;
        width: 0;
      `
    )
  }}

  ${glyphs.stroke.Close} {
    padding-top: 2px;
  }
`
