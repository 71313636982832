import styled, { css, keyframes } from 'styled-components'

import * as colors from '../constants/colors'
import * as fonts from '../constants/fonts'
import * as effects from '../constants/neue/effects'
import * as icons from '../icons/glyphs'
import * as copy from '../styles/copy'

const bounce = keyframes`
  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
`

const debounce = keyframes`
  30% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
`

export const unstyledButtonCss = css`
  ${copy._mediumStyle}
  background: transparent;
  border: none;
  text-align: left;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`
export const UnstyledButton = styled.button`
  ${unstyledButtonCss}
`

export const Base = styled.button`
  align-items: center;
  border-radius: 2px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 900;
  height: 50px;
  justify-content: center;
  outline: none;
  padding: 10px 40px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease;
  text-decoration: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }
`

export const Label = styled.span``

export const buttonColors = {
  black: { base: colors.PRIMARY_GREY_900, hover: colors.PRIMARY_GREY_700, hoverOutlined: colors.PRIMARY_GREY_900 },
  blue: { base: colors.PRIMARY_BLUE_500, hover: colors.PRIMARY_BLUE_700, hoverOutlined: colors.PRIMARY_BLUE_500 },
  green: {
    base: colors.SECONDARY_GREEN_500,
    hover: colors.PRIMARY_GREEN_HOVER,
    hoverOutlined: colors.SECONDARY_GREEN_500,
  },
  grey: { base: colors.PRIMARY_GREY_500, text: colors.PRIMARY_GREY_000, hover: undefined, hoverOutlined: undefined },
  red: { base: colors.PRIMARY_RED_500, hover: colors.PRIMARY_RED_700, hoverOutlined: colors.PRIMARY_RED_500 },
  white: { base: colors.PRIMARY_GREY_000, hover: colors.PRIMARY_GREY_000, hoverOutlined: colors.PRIMARY_BLUE_500 },
}

export type ButtonProps = {
  /** @default 'blue */
  color?: keyof typeof buttonColors
  /** @default 'primary */
  kind?: 'primary' | 'secondary'
  /** @default false */
  $loading?: boolean
  /** @default 'small */
  size?: 'small' | 'medium'
}

export const _disabledStyle = css`
  background-color: ${buttonColors['grey'].base};
  border: 2px solid ${buttonColors['grey'].base};
  color: ${buttonColors['grey'].text};
  cursor: not-allowed;
`

export const Button = styled(Base)<ButtonProps>`
  font-size: 15px;
  line-height: 18px;

  &:disabled {
    ${({ $loading }) => !$loading && _disabledStyle}
  }

  ${({ $loading }) =>
    $loading &&
    css`
      cursor: wait;
    `};

  ${({ color = 'blue', kind = 'primary', $loading = false, theme }) => {
    const primaryColor = theme?.colors?.primary ?? buttonColors[color].base
    const hoverColor = theme?.colors?.hover ?? buttonColors[color].hover

    if (kind === 'secondary') {
      return css`
        background-color: transparent;
        border: 2px solid ${primaryColor};
        color: ${primaryColor};

        ${!$loading &&
        css`
          &:hover {
            border: 2px solid ${primaryColor};
            background-color: ${primaryColor};
            color: ${colors.PRIMARY_GREY_000};
          }

          &:disabled {
            border: 2px solid ${colors.PRIMARY_GREY_500};
            color: ${colors.PRIMARY_GREY_500};
            background-color: transparent;
          }
        `}
      `
    }
    return css`
      background-color: ${primaryColor};
      color: ${colors.PRIMARY_GREY_000};

      ${!$loading &&
      css`
        &:hover {
          background-color: ${hoverColor};
        }

        &:disabled {
          background-color: ${colors.PRIMARY_GREY_500};
        }
      `}
    `
  }}
`

Button.displayName = 'S.button.Button'

type CallToActionProps = {
  /** @default false */
  onlyGlyph?: boolean
} & ButtonProps

export const CallToAction = styled(Button)<CallToActionProps>`
  width: 100%;

  ${icons.Icon} {
    height: 22px;
    ${({ onlyGlyph = false }) =>
      !onlyGlyph &&
      css`
        margin-right: 10px;
      `};
    width: 22px;
  }
`
CallToAction.displayName = 'S.button.CallToAction'

export const EditButton = styled.button<{ onlyGlyph?: boolean }>`
  ${copy._baseStyle};
  align-items: center;
  background-color: ${colors.BACKGROUND_ALERT};
  border-radius: 20px;
  border: 1px solid ${colors.SECONDARY_YELLOW_500};
  box-shadow: ${effects.SHADOW_5};
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  padding: ${(props) => (props.onlyGlyph ? '0px' : '0px 16px')};
  width: ${(props) => (props.onlyGlyph ? '40px' : 'fit-content')};

  ${icons.Icon} {
    color: ${colors.SECONDARY_YELLOW_500};
    margin-right: ${(props) => (props.onlyGlyph ? '0' : '8px')};
    margin-top: 1px;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(233, 174, 90, 0.2) 0%, rgba(233, 174, 90, 0.2) 100%),
      ${colors.BACKGROUND_ALERT};
  }
`
EditButton.displayName = 'S.button.EditButton'

/** @deprecated use the `LinkAsButton` component instead */
export const TextButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${colors.GREY_SHADE_3};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  padding: 13px;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`
TextButton.displayName = 'S.button.TextButton'

export const RoundButton = styled(Base)<{ active?: boolean; hover?: boolean; noShadow?: boolean }>`
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 19px;
  color: ${colors.TEXT_SECONDARY};
  font-weight: 700;
  height: 32px;
  padding: 0 15px;

  ${icons.Icon} {
    height: 17px;
    width: 17px;
    margin: 0 0 0 6px;
    color: ${colors.PRIMARY_GREY_500};
    transition: 0.3s ease;
    animation: ${({ active }) => (active ? debounce : bounce)} cubic-bezier(0.04, 0.4, 0.5, 0.95) 0.8s forwards 1;
  }

  ${({ active, hover }) => {
    if (active && hover) {
      return css`
        color: ${colors.PRIMARY_GREY_000};

        ${icons.Icon} {
          color: ${colors.PRIMARY_GREY_000};
        }

        background-color: ${colors.PRIMARY_RED_500};
      `
    } else if ((active && !hover) || (!active && hover)) {
      return css`
        ${icons.Icon} {
          color: ${colors.PRIMARY_BLUE_500};
        }
      `
    }
  }};

  ${({ noShadow = false }) => {
    if (!noShadow) {
      return css`
        box-shadow: ${effects.SHADOW_1};
      `
    }
  }};
`
RoundButton.displayName = 'S.button.RoundButton'

export const RoundedButton = styled(Base)<{
  disabled?: boolean
  fitToParent?: boolean
  inverted?: boolean
  kind: 'primary' | 'secondary'
}>`
  border-radius: 40px;
  box-shadow: ${effects.SHADOW_5};
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 700;
  gap: 11px;
  height: 40px;
  line-height: 14px;
  padding: 10px 24px;
  text-transform: none;
  width: ${({ fitToParent }) => (fitToParent ? '100%' : 'auto')};
  white-space: nowrap;

  ${({ inverted, kind, theme }) => {
    const primaryColor = theme?.colors?.primary ?? colors.PRIMARY_BLUE_500
    const hoverColor = theme?.colors?.hover ?? colors.PRIMARY_BLUE_700

    return css`
      background-color: ${kind === 'primary'
        ? inverted
          ? colors.PRIMARY_GREY_000
          : primaryColor
        : colors.PRIMARY_GREY_000};
      border: ${kind === 'secondary' && `2px solid ${primaryColor}`};
      color: ${inverted ? primaryColor : kind === 'secondary' ? primaryColor : colors.PRIMARY_GREY_000};

      :hover {
        background-color: ${kind === 'primary' ? (inverted ? primaryColor : hoverColor) : colors.PRIMARY_GREY_000};
        color: ${kind === 'primary' ? (inverted ? colors.PRIMARY_GREY_000 : colors.PRIMARY_GREY_000) : primaryColor};
      }
    `
  }};

  &:disabled {
    background-color: ${colors.PRIMARY_GREY_500};
    color: ${colors.PRIMARY_GREY_000};
    cursor: not-allowed;
  }
`
RoundedButton.displayName = 'S.button.RoundedButton'

export const LinkWrapper = styled.a<{ disabled?: boolean }>`
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'enabled')};
`
LinkWrapper.displayName = 'S.button.LinkWrapper'

export const SliderButton = styled.button<{ size: 'medium' | 'small' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  height: max-content;
  padding: 0;
  min-width: initial;
  background-color: ${colors.PRIMARY_GREY_000};
  border: 1px solid ${colors.PRIMARY_SAND_200};
  transition:
    background-color 0.2s ease-in-out,
    color 0.3s ease-in-out;

  ${({ size }) => {
    if (size === 'medium') {
      return css`
        min-height: 54px;
        width: 54px;
      `
    }

    return css`
      min-height: 40px;
      width: 40px;
    `
  }};

  ${({ theme }) => {
    const primaryColor = theme?.colors?.primary ?? colors.PRIMARY_BLUE_500
    return css`
      &:hover {
        background-color: ${primaryColor};

        ${icons.stroke.CaretLeft}, ${icons.stroke.CaretRight} {
          color: ${colors.PRIMARY_GREY_000};
        }
      }
    `
  }};

  &:active,
  &:focus {
    outline: none;
  }

  ${icons.stroke.CaretLeft}, ${icons.stroke.CaretRight} {
    color: ${colors.PRIMARY_GREY_900};
  }

  &:disabled {
    background-color: ${colors.SECONDARY_GREY_400};
    cursor: not-allowed;

    ${icons.stroke.CaretLeft}, ${icons.stroke.CaretRight} {
      color: ${colors.PRIMARY_GREY_500};
    }
  }
`
SliderButton.displayName = 'S.button.SliderButton'
