import * as Sentry from '@sentry/react'

// import * as consent from '@owl-nest/consent'

const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  SANDBOX: 'sandbox',
} as const

type Configuration = {
  dsn: string | undefined
  environment: (typeof ENVIRONMENT)[keyof typeof ENVIRONMENT]
  user?: User
}

type ExtraData = {
  [id: string]: any
}

type User = Sentry.User & {
  country: string
  currency?: string
  isCompleted?: boolean
  locale: string
  timezone?: string
}

let DEBUG_MODE = false

/* CONFIGURATION */

export const configureScope = Sentry.configureScope

export function init(configuration: Configuration): void {
  DEBUG_MODE = configuration.environment !== ENVIRONMENT.PRODUCTION

  if (!configuration.dsn) {
    console.warn('[logger] Warning: no DSN provided for Sentry')
    return
  }

  try {
    Sentry.init({
      autoSessionTracking: false,
      denyUrls: ['/https?:\\/\\/static.axept.io/'],
      dsn: configuration.dsn,
      environment: configuration.environment,
      ignoreErrors: [
        // Generic non-error rejections
        'Object Not Found Matching Id',
        // Random browser extensions/plugins
        'top.GLOBALS',
        // Meta Platforms, Inc. stuff
        /_AutofillCallbackHandler/,
        'fb_xd_fragment',
        // Apple, Safari and WebKit shenanigans
        /.*@webkit-masked-url.*/, // See https://github.com/getsentry/sentry-javascript/discussions/5875
      ],
      integrations: Sentry.defaultIntegrations,
      normalizeDepth: 10,
      release: process.env.GIT_SHA,
    })
  } catch (error) {
    console.warn("[logger] Warning: Sentry couldn't initialize with the given configuration", {
      dsn: configuration.dsn,
      environment: configuration.environment,
      error,
    })
    return
  }

  if (configuration.user) {
    Sentry.setTag('country', configuration.user.country)
    Sentry.setTag('currency', configuration.user.currency)
    Sentry.setTag('locale', configuration.user.locale)
  }

  // TODO: Remove this when restoring the original `whenGivenFor` instruction below.
  // Sentry.setTag('consent', consent.isGivenFor(consent.Vendor.SENTRY))
  // consent.whenGivenFor(consent.Vendor.SENTRY, {
  //   do: () => {
  //     Sentry.setTag('consent', true)
  //   },
  //   otherwise: () => {
  //     Sentry.setTag('consent', false)
  //   },
  // })

  // TODO: Restore when sh*t stops hitting the fan regarding the "Failed to fetch" errors.
  // consent.whenGivenFor(consent.Vendor.SENTRY, {
  //   do: () => {
  if (configuration.user) {
    setUser(configuration.user)
  }
  //   },
  //   otherwise: () => {
  //     /**
  //      * Sentry, as of version 5, doesn't allow clearing the user from context. Instead, they suggest using `clearContext()`
  //      * which is a bit too strong for our case. We avoid this by resetting the minimal fields manually.
  //      * Please feel free to update this once a better approach is available through the SDK.
  //      */
  //     setUser({
  //       isCompleted: undefined,
  //       locale: undefined,
  //       timezone: undefined,
  //     })
  //   },
  // })
}

export const getCurrentHub = Sentry.getCurrentHub

export const setTag = Sentry.setTag

export function setUser(user: User): void {
  // if (consent.isGivenFor(consent.Vendor.SENTRY)) {
  Sentry.setUser(user)
  // }
}

/* MESSAGE LEVELS */

export function err(error: unknown, extra?: ExtraData, configureScope?: (scope: Sentry.Scope) => void): void {
  if (typeof error === 'string') {
    captureMessage(error, 'error', extra, configureScope)
  } else {
    captureException(error, extra, configureScope)
  }
}

export function inform(message: string, extra?: ExtraData, configureScope?: (scope: Sentry.Scope) => void): void {
  captureMessage(message, 'info', extra, configureScope)
}

export function log(message: string, extra?: ExtraData, configureScope?: (scope: Sentry.Scope) => void): void {
  captureMessage(message, 'log', extra, configureScope)
}

export function warn(message: string, extra?: ExtraData, configureScope?: (scope: Sentry.Scope) => void): void {
  captureMessage(message, 'warning', extra, configureScope)
}

function captureException(exception: any, extra?: ExtraData, configureScope?: (scope: Sentry.Scope) => void): void {
  Sentry.withScope((scope: Sentry.Scope) => {
    if (extra) {
      scope.setExtras(extra)
    }
    if (configureScope !== undefined) {
      configureScope(scope)
    }

    Sentry.captureException(exception)
  })

  if (DEBUG_MODE) print(exception, 'error', extra)
}

function captureMessage(
  message: string,
  severity: Sentry.SeverityLevel,
  extra?: ExtraData,
  configureScope?: (scope: Sentry.Scope) => void,
): void {
  Sentry.withScope((scope: Sentry.Scope) => {
    if (extra) {
      scope.setExtras(extra)
    }
    if (configureScope !== undefined) {
      configureScope(scope)
    }

    Sentry.captureMessage(message, severity)
  })

  if (DEBUG_MODE) print(message, severity, extra)
}

/* eslint-disable no-console */
function print(message: string, severity: Sentry.SeverityLevel, extra?: ExtraData): void {
  let printToConsole
  switch (severity) {
    case 'error':
    case 'fatal':
      printToConsole = console.error
      break
    case 'warning':
      printToConsole = console.warn
      break
    case 'info':
      printToConsole = console.info
      break
    case 'debug':
    case 'log':
    default:
      printToConsole = console.log
      break
  }

  function capitalize(s: string): string {
    if (!s) return ''
    return s[0].toUpperCase() + s.substring(1)
  }

  printToConsole(`${capitalize(severity)}: ${message}`, extra)
}
