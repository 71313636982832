import * as React from 'react'
import styled from 'styled-components'

import { t } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

type PreviewBarProps = {
  previousEditionPath: string
  onShare?: () => void
  translations?: {
    backToEdition: string
  }
}

export function PreviewBar({ previousEditionPath, onShare, translations }: PreviewBarProps): React.ReactElement {
  return (
    <Wrapper>
      <div>
        <plume.styles.copy.S as={plume.Link} href={previousEditionPath}>
          <plume.glyphs.stroke.ArrowLeft size={15} />
          <HiddenOnMobile>{translations?.backToEdition ?? t('Back to project edition')}</HiddenOnMobile>
        </plume.styles.copy.S>
      </div>

      <CenterSlot>
        <plume.styles.heading.XXXXS>
          <plume.glyphs.stroke.Read size={15} /> {t('Preview')}
        </plume.styles.heading.XXXXS>
      </CenterSlot>

      {onShare && (
        <div>
          <plume.LinkAsButton onClick={onShare}>
            <HiddenOnMobile>{t('Share your project')}</HiddenOnMobile>
            <plume.glyphs.stroke.Share size={15} />
          </plume.LinkAsButton>
        </div>
      )}
    </Wrapper>
  )
}

const HiddenOnMobile = styled.span`
  display: none;
  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: inline;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${plume.COLORS.hexToRgb(plume.COLORS.BRAND_YELLOW, 0.5)};
  padding: 12px;
  position: relative;
  pointer-events: all;

  ${plume.glyphs.Icon} {
    margin: 0 8px;
  }

  ${plume.glyphs.stroke.Read} {
    position: relative;
    top: -1px;
  }

  ${plume.styles.heading.XXXXS} {
    color: ${plume.COLORS.PRIMARY_GREY_900};
    margin-bottom: 5px;
    line-height: unset;

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      margin: 0px;
    }
  }

  ${plume.Link}, ${plume.LinkAsButton} {
    text-decoration: none;
    font-weight: 500;
    padding: 0;

    &:hover {
      color: ${plume.COLORS.PRIMARY_BLUE_700};
    }
  }
`

const CenterSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  text-align: center;
`
